import { graphql, useStaticQuery } from "gatsby";
import React, { useState, useEffect } from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo";
import SideBar from '../components/SideBar/SideBar';
import { isSSR } from '../utils/utils';
import "../styles/style-faqs.css";

const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDo4NTI1") {
      id
      title
      content
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }    
    }
  }
}
  `
const Faq = () => {
  const data = useStaticQuery(getdata);
  const common = data.wpgraphql.page;
  const replaceWPUrl = (content) => {
    // check server side rendering
    // https://github.com/gatsbyjs/gatsby/issues/19487
    if (typeof window === 'undefined' || !window.document) {
      return;
    }
    // Remove part
    const WP_URL = '';
    // create blog content obj
    var contentObj = document.createElement('div');
    contentObj.innerHTML = content;

    // get all a href
    var aTags = contentObj.getElementsByTagName('a');
    for (const a of aTags) {
      a.setAttribute("href", a.href.replace(/^.*\/\/[^\/]+/, ''));
    }

    return contentObj.innerHTML
  }

  //
  useEffect(() => {
    if (isSSR()) {
      return;
    }
    //
    const items = document.querySelectorAll('.accordion button');
    items.forEach((item) => item.addEventListener('click', function () {
      const itemToggle = this.getAttribute('aria-expanded');

      for (let i = 0; i < items.length; i++) {
        items[i].setAttribute('aria-expanded', 'false');
      }

      if (itemToggle == 'false') {
        this.setAttribute('aria-expanded', 'true');
      }
    }));
  }, []);

  return (
    <Layout>
      <Seo title="Patent FAQs - PatentPc" description={common.seo.metaDesc} canonical={common.uri} seo={common.seo} />
      <div className="main-bg">
        <div className="overlay"></div>
        <img src="/assets/images/faq1.jpg" alt="Patent FAQs - PatentPc" />
        <h1>Patent FAQs</h1>
      </div>
      <div className="row">
        <div className="col-md-9">

          <div className="Featured f-new fst">
            <div className="featured-content">
              <h3>A Patent Is Essential For A Successful Business</h3>

              <p>Patents are forms of intellectual property assets, so don’t think of patents as a cost but an insurance
                to protect your business. Patents give the owner the right to exclude others from making, using, selling
                or importing the invention. Patents are a way to legally claim ownership of an idea, invention or
                design.</p>
              <p>A patent can last for 20 years depending on various factors. This means that if you have a patent on your
                product it doesn’t mean that someone else won’t be able to make something similar until 2028 (or later).
                However, having a patent does make it more difficult for other people to copy your invention as they
                would have to create their own version from scratch while designing around your patented technology.
                This process can take time and money — instead of simply reproducing what you’ve made without credit or
                compensation.</p>

            </div>
            <div className="feature-image">
              <img src="../assets/images/patent.jpg" alt="" />
            </div>
          </div>

          <div className="Featured f1 f-new">
            <div className="featured-content ">
              <h3>Patents Are Essential For Investment</h3>

              <p>Patents will be essential for investment for many startups. Investors will want to have maximum
                protection for their investments. While they will want rapid traction, they will also look for patented
                technology and know that the investment is backed by a tangible IP asset and they can recoup their
                investment. Further, if your technology is patent-pending or patented, it means that you have the rights
                to your invention and can protect it from being copied or stolen by others.</p>
              <p>Many investors such as medical device investors will not invest in companies without patents. This is
                because if a company cannot prove that its product is unique, then this makes it harder for them to
                attract investors who a business with a protective moat.</p>

            </div>
            <div className="feature-image">
              <img src="../assets/images/investment.jpg" alt="" />
            </div>
          </div>

          <div className="rectangle">

            <h3>Patents Allow A Company To Stay
              Independent</h3>

            <p>Patents are a big part of any business, but they’re especially important for tech
              founders. Patents give a startup the rights to its technology and keep it out of reach
              of competitors. Other companies might try to create similar products or even copy the
              product entirely; with a patent on your solution, you can protect the technology with
              great intellectual property.</p>
            <p>In case you want to speed up growth through partnerships, licensing allows for more
              flexibility in terms of licensing fees and ownership: if someone wants to use your
              technology in exchange for royalties instead of paying upfront costs like buying
              equipment or paying for development time (which can cost thousands upon thousands), then
              licensing might be an option worth considering!</p>
            <p>When it comes time to license your solution, patents also give you more options than
              other companies have when selling their own products—and these options may be essential
              if your goal is profit rather than investment value.</p>


            <h3>Patents Give The Company More
              Options</h3>

            <p>The patent, then, gives you as the owner more options. You can commercialize the
              technology yourself or have others commercialize for you. If you hold a patent, you can
              license your technology in three ways:</p>

            <ul>
              <li>Exclusive licenses – You can grant one or more exclusive licenses to anyone who
                wants to use your technology.</li>
              <li>Non-exclusive Licenses – You can grant several non-exclusive licenses to anyone
                who wishes to use the technology in certain circumstances.</li>
              <li>No license – You can choose not to issue licenses at any time and still have
                full control over who, what, where, and how your product is used.</li>
            </ul>

          </div>

          <div className="Featured f-new fst">
            <div className="featured-content">
              <h3>Patents Protect Your Company From Larger Competitors</h3>

              <p>There are numerous instances of cases of larger companies accused of pilfering from startups. You can
                read about what happened to the photo app Phhhoto <a href="">here</a> .</p>
              <p>Patents give you the legal right to use your invention. Patents also allow you to sue competitors who
                infringe on your patent rights by using your patented product or process without permission or
                compensation.</p>
              <p>In addition to being a defensive strategy that protects new inventions from being stolen, patents can
                also serve as an offensive strategy: if someone else is infringing on one of your patented
                technologies
                by selling their own products with similar features and functionality, then you have the right to
                sue
                them for damages (and potentially force them out of business).</p>

            </div>
            <div className="feature-image">
              <img src="../assets/images/protect.jpg" alt="" />
            </div>
          </div>

          <div className="Featured f1 f-new">
            <div className="featured-content ">
              <h3>Tech Founders Should Patent Their Innovations</h3>

              <p>Patents are an essential part of any business. They allow investors to see that you are more than
                just a
                good idea. The fact that you cared and thought through the rigors of patenting, and your willingness
                to
                do so, shows them that their money is safe in your hands.</p>
              <p>Further, patents protect against competitors who want to use ideas without paying for them first.
                Remember that patents and intellectual property provide insurance against this possibility. While no
                insurance is foolproof, having IP on your side will allow you to sleep better at night.</p>
              <p>Finally, a patent gives your company more options: you can either sell the innovation outright or
                license
                it out as needed, depending on your needs and preferences at any given time throughout their
                lifespan as
                an organization. In this way, patents keep companies independent and when you get too big or too
                influential, it may be tempting for someone else to come along and buy out your patent-protected
                company. That is where the big paydays come for your patent investments.</p>

            </div>
            <div className="feature-image">
              <img src="../assets/images/tech.jpg" alt="" />
            </div>
          </div>

          <div className="container1">
            <h2>1. Introduction to PatentPC</h2>
            <div className="accordion">
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span className="accordion-title">1.1 IP Basics</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Intellectual Property – is a term that refers to creations by the mind. It includes literary and artistic
                    works, inventions, designs, symbols, names, and images.

                    These are some of the intellectual property rights.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-2" aria-expanded="false">
                  <span className="accordion-title">1.1.1 What is a Copyright?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Copyright, also known as author’s rights, is a term that describes the legal rights creators have over their
                    artistic and literary works. Copyright covers everything from music, books, paintings, sculptures, films,
                    computer programs, databases and maps to technical drawings.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-3" aria-expanded="false">
                  <span className="accordion-title">1.1.2 What is a trademark? </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Trademarks are intellectual property that can include words, phrases, or symbols that identify your
                    company’s source. Trademarks also protect logos and brand names used on products or services.
                    <br /> <br />
                    A service mark is similar in appearance to a trademark but distinguishes and identifies who the source of
                    the service is rather than the goods.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">1.1.3 What are trade secrets?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Trade secrets are intellectual Property (IP), rights to confidential information that may be sold or
                    licensed.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-5" aria-expanded="false">
                  <span className="accordion-title">1.1.4 How can we define unfair competition?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Unfair competition protects intellectual property from actions that cause economic harm to a company through
                    deceptive, unfair or illegal behavior.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-5" aria-expanded="false">
                  <span className="accordion-title">1.1.5 What are the three types of patents?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    There are three types of patents:
                  </p>
                  <ul>
                    <li>Utility patents</li>
                    <li>Design patents</li>
                    <li>Plant patents</li>
                  </ul>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-5" aria-expanded="false">
                  <span className="accordion-title">1.2 How do I determine which IP category is best for me?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Start by looking at what you want to protect, how you want it to be protected, and for how long. You may
                    also obtain multiple types of protection if necessary because many of these categories overlap. <br /><br />

                    It is best to speak with an attorney about your specific situation and property.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container1">
            <h2>2. Patent Basics</h2>
            <div className="accordion">
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span className="accordion-title">2.1 What is a patent?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    A patent is an exclusive right that is granted to an invention. A patent is essentially a right that grants
                    exclusive rights to a product or process that provides a new method of doing something or solves a problem
                    in a technical way.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-2" aria-expanded="false">
                  <span className="accordion-title">2.2 What is the legal authority behind patents?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Patents grant the patent holder exclusive rights to prevent others from using, making, importing, or selling
                    the patented invention for a short period.
                  </p>
                  <p>The U.S. Patent Act, 35 U.S.C. §§ 1 et seq. was enacted by Congress by its Constitutional grant to ensure
                    that inventors have exclusive rights to their discoveries for a limited time.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-3" aria-expanded="false">
                  <span className="accordion-title">2.3 What government agency is in charge of administering patents?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    The U.S. Patent and Trademark Office(USPTO) is the agency responsible for granting U.S. patents and
                    registering trademarks.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">2.4 What are the advantages of owning a patent?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    <ul>
                      <li>Patents give you the right to prevent others from using, copying, selling, or importing your invention
                        without permission. </li>
                      <li>You get protection for a pre-determined period, allowing you to keep competitors at bay.</li>
                      <li>Then, you can use your invention.</li>
                      <li>You can also license your patent for others to use it others or sell it. This could be a great source of
                        revenue for your company.</li>
                    </ul>
                  </p>
                </div>
              </div>

            </div>
          </div>

          <div className="container1">
            <h2>2.5 Factors to consider before Patenting:</h2>
            <div className="accordion">
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span className="accordion-title">2.5.1 Is the invention commercialized?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    To bring your invention to market, you will need to think like an entrepreneur.
                  </p>
                  <p>You can commercialize by:</p>
                  <ul>
                    <li>Venturing- Venturing is the process of creating a company to help bring your patent to fruition.</li>
                    <li>Licensing your patent to a major company to make it commercially viable.</li>
                  </ul>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-2" aria-expanded="false">
                  <span className="accordion-title">2.5.2 Did I invent the invention?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Patents can only be obtained if the invention is your own..</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-3" aria-expanded="false">
                  <span className="accordion-title">2.5.3 Do I own the invention?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    You should be the owner of the rights to your invention.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">2.5.4 Is the invention patentable?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Patentable invention is a non-obvious and new process, machine or composition of matter or any improvement
                    thereof. It also includes ornamental designs and new varieties of sexually reproduced plants.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">2.5.5 Does it fit into a patent “class”?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Patent classification is a system for organizing all U.S. patent documents and other technical documents
                    into specific technology groupings based on common subject matter.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">2.5.6 Is it novel?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    An invention is considered novel when it is different from all previous inventions (called “prior art”) in
                    one or more of its constituent elements.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">2.5.7 Is it non-obvious?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    One of the requirements to obtain a patent is non-obviousness. If someone with ordinary skill in the
                    relevant field can easily create an invention from prior art, a claimed invention is usually obvious.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="true">
                  <span className="accordion-title">3. How to set up an account with the USPTO?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Are you wondering how to set up your account with USPTO?
                  </p>
                  <p>There are a few factors before you set up your account:</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">3.1 Are you a new user? Here’s how to get Started.</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    These are the three steps to get started:
                  </p>
                  <ul>
                    <li>Create a USPTO.gov account.</li>
                    <li>Create a Customer Number.</li>
                    <li>Submit the Patent Electronic Verification Form to register your USPTO.gov account and link your customer
                      number to access and use the Patent Electronic Systems.</li>
                  </ul>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">3.2 What are the key considerations in USPTO Registration Modernizing?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    The Trademark Modernizing Act(TMA) includes key provisions that will give the USPTO and trademark owners
                    additional tools to better protect and strengthen the integrity of the federal trademark register.
                  </p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">3.3 What filing types are available at USPTO.gov Account?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    There are three types of patents: utility, design, and plant. There are two types of utility and plant
                    patent
                    applications: provisional and nonprovisional. Under the provisional and nonprovisional types of patent
                    filing
                    are the filing types under each type.
                  </p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="true">
                  <span className="accordion-title">4. Will I need a patent attorney or agent for the application process?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    You can draft and file your own applications, but patent attorneys and agents are experts and can help you
                    draft the application in a professional manner.
                  </p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">4.1 What’s the difference between a patent attorney and an agent?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    A patent agent is someone who has passed the Patent Bar through the U.S. Patent and Trademark Office (USPTO)
                    while, a patent attorney is a person who has gone to and graduated from law school. This person has passed
                    the
                    State Bar exam as well as the USPTO exam.
                  </p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">4.2 What is the process to become a USPTO registered patent attorney or patent
                    agent?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Registered patent practitioners are individuals who have passed the USPTO’s registration exam and met the
                    qualifications to represent patent applicants before the USPTO.
                  </p>
                  <p>The Office of Enrollment and Discipline (OED) administers the registration exam and maintains a roster of
                    current patent practitioners. OED also investigates grievances submitted against practitioners, provides
                    public information about disciplinary actions against practitioners, coordinates a nationwide Patent Pro
                    Bono
                    Program for under-resourced inventors seeking free legal help, and administers a Law School Clinic
                    Certification Program where law students gain experience in intellectual property law.</p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span className="accordion-title">4.3 Is there paperwork that an attorney may ask me to sign for his/her
                    services?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    When working with an attorney, he/she may ask you to sign a power of attorney.
                  </p>
                  <p>
                    The power of attorney shows that you have appointed him/her to represent you and handle the patent
                    application
                    process on your behalf.</p>

                </div>
              </div>
            </div>

          </div>

          <div className="container1">
            <h2>5. What is a provisional patent application (PPA)?</h2>
            <div className="accordion">
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span className="accordion-title">5.1 What is provisional patent application (PPA)?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    A provisional patent application (PPA) is a document issued by the U.S. Patent and Trademark Office (USPTO)
                    that helps protect a new invention from being copied during the 12-month period before a formal patent
                    application is filed.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.2 What are the benefits of a provisional patent?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <ul>
                    <li>You enjoy less formal requirements.</li>
                    <li>It provides foreign priority for subsequent patent applications filed in countries that are signatories
                      to the Paris Convention.</li>
                    <li>It enables immediate commercial promotion of the invention.</li>
                    <li>It is a relatively efficient and cost-effective way of protecting an invention during the early
                      development process.</li>
                    <li>It effectively adds an additional year to the term of the patent grant.</li>
                  </ul>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.3 What are the drawbacks of a provisional patent?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <ul>
                    <li>Inaccuracy will undo your protection.</li>
                    <li>Modifying your invention or adding new improvements will force you to file a new provisional application
                      or regular patent application.</li>
                    <li>You must file foreign patent applications within a year.</li>

                  </ul>
                </div>
              </div>

            </div>

          </div>

          <div className="container1">
            <h2>5.4 What is the recommended format for a provisional patent application (PPA)?</h2>
            <div className="accordion">
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span className="accordion-title">5.4.1 Does it have a title?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    The title is a brief description of your invention
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.4.2 Does it contain an abstract?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    The abstract allows readers to identify the nature of technical disclosures for the invention quickly.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.4.3 Are drawings present in the PPA?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Drawings are required to understand the invention according to the USPTO, and they should be submitted with
                    the application.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.4.4 Description</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    The description means that you must provide thorough and thoughtful disclosures, including
                    implementing-construct and using your invention.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.4.5 Prior art</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    You should note any prior art relevant to your invention and describe how it differs from the rest.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.4.6 Inventor</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Anyone who contributed to the PPA’s description and drawings may be listed as an inventor.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.4.7 Multiple inventions</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Multiple invention disclosures can be included in one patent application. However, the USPTO may charge
                    additional fees for exceeding a specified number of pages in your application.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.4.8 Fees</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    When you submit your PPA, you must pay the filing fees.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.5 What should I think about in drafting the PPA application?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Before you begin the filing process, it is essential to determine whether your idea can be patentable.
                    Consider factors like cost, time, type, and the subject matter of your invention.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.6 What is optional for a PPA?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    PPAs cannot be evaluated, so there are no requirements to include claims or prototypes. It is always
                    possible to leave it out.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.6.1 Are claims a requirement for a PPA?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    While they are not required for a PPA application, they are necessary for utility patent applications.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.6.2 Is a prototype a required utility for PPA?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    For a utility or provisional application, a prototype is not necessary. A prototype is not required for a
                    utility or provisional application.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">5.7 What do you do for improvements after PPA filing?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    To avoid filing another PPA, you may include additional information in the utility application. However, you
                    may file another PPA if you are still not close to the utility filing deadline.
                  </p>
                </div>
              </div>

            </div>

          </div>

          <div className="container1">
            <h2>6. What next after you submit a PPA?</h2>
            <div className="accordion">
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span className="accordion-title">6.1 What does the USPTO do?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    The patent office will electronically record your submission and give you an electronic filing receipt that
                    basically confirms they received your files and your filing fee. A few weeks after that someone at the
                    patent office will look at your submission to make sure all the required pieces are there, including:
                  </p>
                  <ul>
                    <li>A specification (description of your invention)</li>
                    <li>A cover sheet or transmittal letter that lists the invention title, inventor names, and city/state.</li>
                    <li>A proper filing fee (currently $130 for small entity or $65 for micro entity) was paid and received.
                    </li>
                  </ul>
                  <p>If you have all of these items, you will get an Official Filing Receipt from the USPTO.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">6.2 What can you do?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Upon filing receipt, you can mark the product as “patent in progress.” However, you have only one year of
                    protection under the PPA. It is best to convert the product to non-provisional applications before one year.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">6.2.1 Do you mark product with “patent pending” or “patent applied”?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Your application will be considered pending once the USPTO has sent you your filing receipt. If you sell or
                    make a patented product, you can show the “patent granted” or “patent pending phrase. This applies to all
                    applications, including a PPA, utility patent, or design patent.
                  </p>
                  <p>Although the term “patent in process” is more popular, it means “patent filed.”</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">6.2.2 Is it possible to file Multiple PPAs?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    While you can file multiple provisional patent applications as necessary, it is essential to convert all
                    provisional applications within one calendar year of their earliest filing date.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">6.2.3 How do you convert a PPA to a utility patent application?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    You will file your non-provisional patent application, and in this application, you will claim the filing
                    date of the provisional patent application. Second, you can petition that your provisional patent
                    application be converted to a full patent.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span className="accordion-title">7. What is Utility Patent Application?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    A utility patent is a form of intellectual property that protects what an invention is, how an invention
                    works and/or how an invention is made and/or used, i.e., the “functional” aspects of the invention. For your
                    invention to qualify for utility patent protection, it must be a machine, process or method, article of
                    manufacture, and/or composition of matter.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.1 What are the preparatory steps before drafting your Utility Patent
                    Application?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>

                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.1.1 Document the invention process</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Although electronic records can be used as an inventor’s notes, the best option is still a bound notebook
                    with consecutively numbered pages. This inventor’s notebook is used to record when and how you first started
                    building it.
                  </p>
                  <p>
                    This is crucial because the inventor’s notebooks and corroborating evidence have been vital in proving who
                    invented the invention. Even with the first to file system, inventor notebooks can still prove derivation.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.1.2 Search for prior art</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    A patent search may be helpful at different stages of an invention process.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.1.3 Be aware of imperfections in the search process</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    You ought to be aware of some imperfections that may come up during the patent search process, These are
                    some that could come up:
                  </p>
                  <ul>
                    <li>If you don’t publish pending applications, they won’t show up.</li>
                    <li>It is possible to search keyword searches for incorrectly classified patents in the databases.</li>
                    <li>Recent patents that have been granted won’t be immediately in the system.</li>
                    <li>Patent searches won’t uncover foreign patents.</li>
                  </ul>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.2 Inventorship – what happens when you have multiple inventors?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Yes, a patent can have one or multiple inventors. However, the number of inventors is dependent on those
                    that invented the claimed invention. United States Patent Law requires that all inventors must be named in a
                    patent application.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.2.1 How can an improper inventorship be fixed?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    To correct inventorship, one must:
                  </p>
                  <ul>
                    <li>File a request to change inventorship that sets forth the desired inventorship;</li>
                    <li>A statement from each person being added or deleted that the error in inventorship occurred without
                      deceptive intent;</li>
                    <li>An oath or declaration of the actual inventors;</li>
                    <li>The fee; and</li>
                    <li>Written consent of the assignee (where applicable).</li>
                  </ul>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.2.2 How can you correct typos in the name of an inventorship?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    The fix may get made at both the USPTO PAIR record and the USPTO assignment record but won’t change the face
                    of the printed patent. They can seek a Certificate of Correction which goes to the end of the printed patent
                    to fix typos.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.2.3 How is ownership of an invention determined?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    The creator or originator of an idea, work, or novel invention is presumed to own the copyright to their
                    creations. However, if the work was created as a part of a work-made-for-hire agreement, or in an
                    employer-employee agreement, the copyright belongs to the employer.

                  </p>
                  <p>If two or more inventors are named on a patent application, the patent rights most likely are jointly owned
                    by the joint inventors unless a written Patent Assignment agreement has been executed by the joint inventors
                    assigning their ownership rights to another individual or company.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.3 How can you protect your ownership if you hire consultants to work on your
                    invention?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <ul>
                    <li>Register copyrights, trademarks, and patents.</li>
                    <li>Register business, product or domain names.</li>
                    <li>Create confidentiality, non-disclosure or licensing contracts for employees and partners.</li>
                    <li>Implement security measures.</li>
                    <li>Avoid joint ownership.</li>
                  </ul>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.3.1 What is a consultant’s Agreement?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>The consulting agreement is an agreement between a consultant and a client who wishes to retain certain
                    specified services of the consultant for a specified time at a specified rate of compensation. The terms of
                    the agreement can be quite simple or very complex.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.3.2 Joint Ownership Agreement (JOA)</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>It states that, subject to an agreement between them, co-owners are entitled to an equal, undivided share
                    in
                    the patent and are entitled to perform acts which, under other circumstances, would infringe the patent. In
                    short, both owners are entitled to use the invention without requiring permission from the other.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.2.3 How is ownership of an invention determined?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>The creator or originator of an idea, work, or novel invention is presumed to own the copyright to their
                    creations. However, if the work was created as a part of a work-made-for-hire agreement, or in an
                    employer-employee agreement, the copyright belongs to the employer.
                  </p>
                  <p>If two or more inventors are named on a patent application, the patent rights most likely are jointly owned
                    by the joint inventors unless a written Patent Assignment agreement has been executed by the joint inventors
                    assigning their ownership rights to another individual or company.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.3 How can you protect your ownership if you hire consultants to work on your
                    invention?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <ul>
                    <li>Register copyrights, trademarks, and patents.</li>
                    <li>Register copyrights, trademarks, and patents.
                      Register business, product or domain names.</li>
                    <li>Create confidentiality, non-disclosure or licensing contracts for employees and partners.</li>
                    <li>Implement security measures.</li>
                    <li>Avoid joint ownership.</li>
                  </ul>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.3.1 What is a consultant’s Agreement?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>The consulting agreement is an agreement between a consultant and a client who wishes to retain certain
                    specified services of the consultant for a specified time at a specified rate of compensation. The terms of
                    the agreement can be quite simple or very complex.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.3.2 Joint Ownership Agreement (JOA)</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>It states that, subject to an agreement between them, co-owners are entitled to an equal, undivided share
                    in
                    the patent and are entitled to perform acts which, under other circumstances, would infringe the patent. In
                    short, both owners are entitled to use the invention without requiring permission from the other.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.4 Does your employer have rights in your invention?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>Typically, employers are entitled to all intellectual property created at/for their business, unless there
                    exists a contract stating otherwise.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">7.4.1 What happens when your employer isn’t interested in your invention
                    submission?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>You can request a release from your employer if you have told your employer about your invention, but the
                    company isn’t interested. A release is a document that allows the employer to return your invention.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span className="accordion-title">8. What is the process of filing a Utility Patent Application?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>A nonprovisional utility patent application can be filed with the USPTO through the Office’s electronic
                    filing system called EFS-Web, delivery by U.S. mail, or hand delivery to the Office in Alexandria, Virginia.
                    By far, most patent applications filed at the USPTO are utility applications.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.1 Can I reuse information from my PPA or other applications?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>Your utility application should be based from your PPA so you should reuse the PPA application. Reusing
                    information from another patent applications is not prohibited, but you should: Cite the patent or published
                    application in an IDS.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.2 What should I consider in drafting the utility application?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <ul>
                    <li>Your utility application should include:</li>
                    <li>Specification including Background, Summary of the Invention, Brief Description of the Drawings,
                      Detailed
                      Description, Claims, Abstract, and Drawings.</li>
                    <li>Information Disclosure Statement (IDS)</li>
                    <li>Inventor’s Oath or Declaration.</li>
                    <li>Filing Fees.</li>
                    <li>Transmittal letter.</li>
                  </ul>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.3 How do I write the claims?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.4 What documents are needed in filing the utility application?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>According to USPTO, these are the documents required in filing the utility application:</p>
                  <ul>
                    <li>Utility Patent Application Transmittal Form or Transmittal Letter</li>
                    <li>Appropriate Fees</li>
                    <li>Application Data Sheet (see 37 CFR § 1.76)</li>
                    <li>Specification (with at least one claim)</li>
                    <li>Drawings (when necessary)</li>
                    <li>Executed Oath or Declaration</li>
                    <li>Nucleotide and Amino Acid Sequence Listing (when necessary)</li>
                    <li>Large Tables or Computer Listings (when necessary)</li>
                  </ul>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.4.1 What is a specification?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>A specification is a set of documents that describes the invention in detail and how it is made, used, or
                    worked.</p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.4.2 Information Disclosure Statement (IDS)</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>This is a submission of background art or information about previous art to the United States Patent and
                    Trademark Office by a patent applicant during the patent prosecution process.</p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.4.3 Inventor’s Oath or Declaration</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>This is a form that the USPTO has provided that allows you to declare whether you are an original inventor
                    or
                    not.</p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.4.4 Fees</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>A patent cost vary depending on the type of patent and how many tasks the attorney must perform as well as
                    the complexity of the invention. All rates are available at the USPTO.</p>
                  <p>Fees depend on many factors, such as whether you are eligible for the small entity discount or if you are
                    filing for power-of- attorney. For the most current information, visit the USPTO website.</p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.4.5 Transmittal letter</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>The utility patent application transmittal form, also referred to as a transmittal letter, must be filed
                    along with your application. This form specifically identifies what is being included in your application,
                    which includes specification, claims, illustrations, oath, and information disclosure statement.</p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.4.6 Drawings</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>Drawings can be part of the specification. They can be very useful tools to show the operation of the
                    invention.</p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.4.7 Optional</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>These include other documents that are not necessarily required in filing. These are named below.</p>

                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.5 Can you request early publication of your application?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>Interested applicants may submit a request for early publication of their application in compliance with 37
                    CFR 1.219. The USPTO will publish the application as soon as possible if it is otherwise ready for
                    publication.</p>

                </div>
              </div>
              <div className="accordion-item">

                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.6 What is a secrecy order?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>A secrecy order bars the award of a patent, orders that the invention be kept secret, restricts the filing
                    of
                    foreign patents, and specifies procedures to prevent disclosure of ideas contained in the application.</p>

                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.7 What happens after the utility application is filed?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>Upon filing the non-provisional patent application, the Patent Office reviews your patent application for
                    formality before it is assigned over to an examiner.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">8.8 How to Respond to Formality/Pre-Examination Review?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>After receiving your application, the PTO will verify that it is complete and ready for examination. If
                    your
                    application is incomplete, has no claim, or pays filing fees, a Notice of Missing Parts may be sent. Any
                    deficiencies or irregularities in new applications will be communicated to you through different types of
                    pre-examination notices. These notices require that the missing items be submitted or corrected within the
                    time frame for replying (typically one to two months). All requirements must be met.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span className="accordion-title">9. How do you respond to office actions?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>An Office action is written correspondence from the patent examiner that requires a properly signed written
                    response from the applicant in order for prosecution of the application to continue.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">9.1 First Office Action (OA)</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>Once your utility patent application has been approved by the PTO (typically within six months to two
                    years),
                    an examiner will review the case and report the results in a document known as a first Office Action (OA).
                    You
                    can apply for a status inquiry with the USPTO if you do not hear back from the PTO in the time expected.</p>
                  <p>A first Office action on the merits (FAOM) is typically the first substantive examination of the
                    application.
                  </p>

                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">9.1.1 How is an office action managed?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <ul>
                    <li>Review the rejection and understand its reasons.</li>
                    <li>Then respond to every point in the rejection.</li>
                    <li>Next, contact the patent examiner to request an interview. During the interview, you can explain your
                      position to him.</li>
                    <li>The examiner will send you a summary of the interview after the interview. You can reply to this summary
                      if it is not satisfactory.</li>
                  </ul>
                  <p>The deadline for filing your response is usually three months after the Office Action was sent.</p>

                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">9.1.2 What is first office action allowance (Ex parte Quayle)?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>This is issued when there are minor issues to be resolved in a patent application that is otherwise in
                    condition for allowance. Prosecution on the merits is closed, meaning that substantive examination has
                    concluded.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">9.1.3 What is amendment in response to Office Action (OA)?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Review the OA and consider any materials cited by the examiner. Then, conteplate how you can describe your
                    invention in order to counter the examiner’s arguments. To address the issues raised by the patent examiner,
                    you should draft an amendment. Include the following:</p>
                  <ul>
                    <li>A summary of the amendment.</li>
                    <li>Reply to rejections by explaining in detail how your claims have changed or how you differ from the
                      prior
                      art.</li>
                    <li>Conclude your letter.</li>
                  </ul>

                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">9.1.4 How is request for an interview with the examiner made?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>Applicants should call the examiner to request an interview. The examiner’s contact information can be
                    found
                    in the Conclusion section of the most recent Office action.</p>
                  <p>An applicant may also complete and submit an Application Initiated Interview Request Form (PTOL-413A) to
                    request an interview with an examiner.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">9.1.5 What happens if a response is not timely filed?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>If an applicant does not submit an acceptable timely response to an Office action, the application will be
                    held abandoned.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">9.2 What is second or Final Office Action (OA)?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Final Office actions are usually second office actions sent by the examiner following a review of the
                    applicant’s response. You can choose to receive a reply as a notice of acceptance or rejection.
                  </p>
                  <p>A second or subsequent office action can be characterized as final as long as it does not include a new
                    ground of rejection that is neither necessitated by an amendment of the claims nor based on an Information
                    Disclosure Statement.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">9.2.1 What is a notice of Allowance?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>A Notice of Allowance is a document sent to a patent applicant from the United States Patent and Trademark
                    Office (USPTO) after a patent examiner has decided to issue the requested patent.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">9.2.2 What is reconsideration?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>In order to be entitled to reconsideration, the patent owner must respond to the Office action. 37 CFR
                    1.111(b). The patent owner may respond to such Office action with or without amendment and the patent under
                    reexamination will be reconsidered, and so on repeatedly unless the examiner has indicated that the action
                    is
                    final.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">9.2.3 What is amendment?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Patent claims must be amended using the prescribed Form 13, alongside payment of the prescribed fee.</p>
                  <p>The amendment seeks to enlarge the scope of the claims of the patent or introduce new subject matter.</p>


                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">9.2.4 How is request to continue the examination after a final office action
                    done?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Requests for Continued Examination (RCE) is a part of how to patent an idea and asks a patent examiner to
                    review a patent application after the inventor has made necessary changes to an original application. It’s
                    one
                    of the last steps in the patent application process.
                  </p>
                  <p>When you file a patent application, you have two main chances for approval. If you receive a rejection the
                    first time, you can fix the problems and send your application back. A second rejection is the Final Office
                    Action.</p>
                  <p>To continue the patent application case, you might have to file an RCE. This reopens your case and allows
                    the
                    examination to continue.
                  </p>
                  <p>After you file an RCE, the patent examiner might allow continued examination. Rather than granting an
                    application, the examiner might issue an Office Action.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="container1">
            <h2>10. What next after The USPTO has issued your patent?</h2>
            <div className="accordion">
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span className="accordion-title">10.1 How long does a utility patent last?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    A U.S. utility patent, explained above, is generally granted for 20 years from the date the patent
                    application is filed; however, periodic fees are required to maintain the enforceability of the patent.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-2" aria-expanded="false">
                  <span className="accordion-title">10.2 What is patent expiration?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    As part of the how to patent an idea process, in the United States, patents expire within 14-20 years from
                    the filing date, depending on what you’ve patented and when. Once this happens, your idea passes into the
                    public domain.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <button id="accordion-button-3" aria-expanded="false">
                  <span className="accordion-title">10.3 What is a patent term extension and what are the conditions to extensions
                    to the Patent? </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    The purpose is to compensate patent holders for the delays that occurred during regulatory review. An
                    application for patent term extension can be submitted to the Director of the Patent Office, by the patent
                    owner or its agent.
                  </p>
                  <ul>
                    <li>The patent must not be expired at the time the patent term extension is submitted.</li>
                    <li>The patent term must never have been previously extended.</li>
                    <li>The patented invention must have been subject to a period of regulatory review prior to being being
                      marketed or used.</li>
                    <li>The patented invention must specifically be recited in the patent claims.</li>
                    <li>Patent term extension application must be made within 60 days of obtaining regulatory approval.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3" style={{ paddingTop: 100 + 'px'}}>
          <SideBar></SideBar>
        </div>
      </div>
    </Layout>
  );
};

export default Faq;
